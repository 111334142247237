<template>
  <span>
    <v-dialog v-model="dialogDetalhes" fullscreen :scrollable="true">
      <v-card v-if="dialogDetalhes">
        <v-card-title class="py-2 text-center">
          <div class="headline">Detalhes validação</div>
          <div class="body-1">
            {{ dialogDetalhesSelected.nomeServico }} -
            {{
              `${competencia_de}-01` | parseToCustomDateFormat("MM/YYYY")
            }}
            até
            {{ `${competencia_ate}-01` | parseToCustomDateFormat("MM/YYYY") }}
          </div>
        </v-card-title>
        <v-card-text class="py-0 px-2">
          <v-row dense justify="space-around">
            <v-col sm="12" md="3" class="py-0 mb-1">
              <v-text-field
                v-model="searchDataTableDetalhesIndicador"
                clearable
                append-icon="mdi-magnify"
                label="Filtrar municípios"
                hide-details
                single-line
              />
            </v-col>
            <v-col sm="12" md="3" class="py-0 mb-1">
              <v-checkbox
                label="Mostrar apenas linhas com Erro Crítico"
                v-model="mostrarApenasLinhasComErroCritico"
                hide-details
              />
            </v-col>
            <v-col cols="auto" v-if="userInfo.admin">
              <v-btn
                color="primary"
                dark
                elevation="1"
                relative
                text
                small
                :loading="downloadingArquivoLogsAprovacoes"
                @click="downloadArquivoLogsAprovacoes()"
              >
                <v-icon left size="24px">
                  mdi-text-box-outline
                </v-icon>
                Exportar Logs
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :headers="dialogDetalhesHeader"
            :items="filteredItemsDialogDetalhesIndicador"
            dense
            class="elevation-1 style-table-dialog-detalhes"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, 100, 200, -1],
              showCurrentPage: true,
              showFirstLastPage: true,
            }"
            :mobile-breakpoint="10"
          >
            <template
              v-for="headerValue in indicadoresLiberados"
              v-slot:[getHeaderSlotName(headerValue)]="item"
            >
              <span :key="headerValue">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.header.text }}
                    </span>
                  </template>
                  <span>
                    {{ descricaoPorIndicador[headerValue] }}
                  </span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:[`header.aprovacao_realizados`]="{ header }">
              <v-row justify="end" no-gutters>
                <v-checkbox
                  v-model="flagTodosAprovadoServRealizados"
                  :ripple="false"
                  hide-details
                  class="my-0"
                  :disabled="
                    savingCienteValidacao || !userIsAuthorizedByField()
                  "
                  @change="triggerTodosAprovadoServRealizados"
                >
                  <template #prepend>
                    <label class="v-label">
                      {{ header.text }}
                    </label>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template v-slot:[`header.aprovacao_ssp`]="{ header }">
              <v-row justify="end" no-gutters>
                <v-checkbox
                  v-model="flagTodosAprovadoServSsp"
                  :ripple="false"
                  hide-details
                  class="my-0"
                  :disabled="
                    savingCienteValidacao || !userIsAuthorizedByField()
                  "
                  @change="triggerTodosAprovadoServSsp"
                >
                  <template #prepend>
                    <label class="v-label">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ header.text }}
                          </span>
                        </template>
                        <span>
                          Solicitados, Suspensos e Pendentes
                        </span>
                      </v-tooltip>
                    </label>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template v-slot:[`header.aprovacao_compensacoes`]="{ header }">
              <v-row justify="end" no-gutters>
                <v-checkbox
                  v-model="flagTodosAprovadoServCompensacoes"
                  :ripple="false"
                  hide-details
                  class="my-0"
                  :disabled="
                    savingCienteValidacao || !userIsAuthorizedByField()
                  "
                  @change="triggerTodosAprovadoServCompensacoes"
                >
                  <template #prepend>
                    <label class="v-label">
                      {{ header.text }}
                    </label>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template v-slot:[`item.nome_municipio`]="{ item }">
              <span style="white-space: nowrap;">
                {{ item.nome_municipio }}
              </span>
            </template>
            <template
              v-for="indicador in indicadoresLiberados"
              v-slot:[getItemSlotName(indicador)]="{ item }"
            >
              <div :key="indicador" style="white-space: no-wrap">
                <span class="font-weight-normal">
                  {{ item[indicador] | parseNumberToFloatBrIgnoringNaN }}
                </span>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        style="padding-bottom: 1.5px;"
                        :color="
                          {
                            VALIDO: 'success',
                            ALERTA: 'warning',
                            ERRO_CRITICO: 'error',
                          }[item[`tipo_validacao_${indicador}`]]
                        "
                      >
                        {{
                          {
                            VALIDO: "mdi-check-circle-outline",
                            ALERTA: "mdi-alert-circle-outline",
                            ERRO_CRITICO: "mdi-close-circle",
                          }[item[`tipo_validacao_${indicador}`]]
                        }}
                      </v-icon>
                    </span>
                  </template>
                  <span
                    :class="
                      {
                        VALIDO: 'success--text font-weight-bold',
                        ALERTA: 'warning--text font-weight-bold',
                        ERRO_CRITICO: 'error--text font-weight-bold',
                      }[item[`tipo_validacao_${indicador}`]]
                    "
                  >
                    {{
                      {
                        VALIDO: "",
                        ALERTA: "ALERTA:",
                        ERRO_CRITICO: "ERRO CRÍTICO:",
                      }[item[`tipo_validacao_${indicador}`]]
                    }}
                  </span>
                  <span>
                    {{ item[`validacao_${indicador}`] }}
                  </span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.aprovacao_realizados`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.aprovacao_realizados"
                    hide-details
                    :disabled="
                      savingCienteValidacao || !userIsAuthorizedByField()
                    "
                    :ripple="false"
                    :success="item.aprovacao_realizados === true"
                    :error="item.aprovacao_realizados === false"
                    :indeterminate="item.aprovacao_realizados === null"
                    class="my-0"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-medium"
                        :class="
                          getCheckboxAprovacaoClass(item.aprovacao_realizados)
                        "
                        style="font-size: 12.5px"
                      >
                        {{
                          getCheckboxAprovacaoText(item.aprovacao_realizados)
                        }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.aprovacao_ssp`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.aprovacao_ssp"
                    hide-details
                    :indeterminate="item.aprovacao_ssp === null"
                    :disabled="
                      savingCienteValidacao || !userIsAuthorizedByField()
                    "
                    :ripple="false"
                    :success="item.aprovacao_ssp === true"
                    :error="item.aprovacao_ssp === false"
                    class="my-0"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-medium"
                        :class="getCheckboxAprovacaoClass(item.aprovacao_ssp)"
                        style="font-size: 12.5px"
                      >
                        {{ getCheckboxAprovacaoText(item.aprovacao_ssp) }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.aprovacao_compensacoes`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.aprovacao_compensacoes"
                    hide-details
                    :indeterminate="item.aprovacao_compensacoes === null"
                    :disabled="
                      savingCienteValidacao || !userIsAuthorizedByField()
                    "
                    :ripple="false"
                    :success="item.aprovacao_compensacoes === true"
                    :error="item.aprovacao_compensacoes === false"
                    class="my-0"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-medium"
                        :class="
                          getCheckboxAprovacaoClass(item.aprovacao_compensacoes)
                        "
                        style="font-size: 12.5px"
                      >
                        {{
                          getCheckboxAprovacaoText(item.aprovacao_compensacoes)
                        }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-0 pr-6">
          <v-spacer />
          <v-tooltip top v-if="!userIsAuthorizedByField()">
            <template v-slot:activator="{ on, attrs }">
              <div class="pr-2" v-bind="attrs" v-on="on">
                <v-icon large color="warning">
                  mdi-alert-circle-outline
                </v-icon>
              </div>
            </template>
            <span>Você não possui autorização para validar esse serviço</span>
          </v-tooltip>
          <v-btn
            color="success"
            :loading="savingCienteValidacao"
            :disabled="!userIsAuthorizedByField()"
            @click="salvaDadosCiente"
          >
            Salvar
          </v-btn>
          <v-btn
            text
            color="primary"
            class="text-h2 pa-12"
            @click="dialogDetalhes = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  export default {
    name: "ServicosV2VisaoDetalhadaDialogDetalhes",
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      chavesIndicadores: {
        type: Array,
        required: true,
      },
      indicadoresPorTema: {
        type: Object,
        required: true,
      },
      indicadoresLiberados: {
        type: Array,
        required: true,
      },
      authorizedServicos: {
        type: Array,
        required: false,
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
      descricaoPorIndicador: {
        type: Object,
        required: true,
      },
      nomesServicos: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      dialogDetalhes: false,
      dialogDetalhesSelected: {},
      dialogDetalhesHeader: [],
      dialogDetalhesData: [],
      flagTodosAprovadoServRealizados: false,
      flagTodosAprovadoServSsp: false,
      flagTodosAprovadoServCompensacoes: false,
      savingCienteValidacao: false,
      searchDataTableDetalhesIndicador: "",
      mostrarApenasLinhasComErroCritico: false,
      downloadingArquivoLogsAprovacoes: false,
    }),
    computed: {
      filteredItemsDialogDetalhesIndicador() {
        const search = this.searchDataTableDetalhesIndicador || "";
        let items = this.dialogDetalhesData;

        if (search.trim()) {
          // Filtro de municipio
          items = this.dialogDetalhesData
            .filter((data) =>
              data.nome_municipio.toLowerCase().includes(search.toLowerCase())
            )
            .concat(
              this.dialogDetalhesData.filter((data) =>
                data.serv_002.toLowerCase().includes(search.toLowerCase())
              )
            );
        }

        if (this.mostrarApenasLinhasComErroCritico) {
          items = this.dialogDetalhesData.filter((data) => {
            let found = false;

            for (const indicador of this.indicadoresLiberados) {
              found = data[`tipo_validacao_${indicador}`] === "ERRO_CRITICO";

              if (found === true) break;
            }

            return found;
          });
        }

        return items;
      },
      userInfo() {
        return this.$store.getters.getUserInfo;
      },
    },
    methods: {
      getHeaderSlotName(name) {
        return `header.${name}`;
      },
      getItemSlotName(indicador) {
        return `item.${indicador}`;
      },
      getDadosDialogDetalhes(servico) {
        this.$emit("updateLoadingDados", true);

        IndicadoresServicosV2Service.getValidacoesByServico(
          this.competencia_de,
          this.competencia_ate,
          servico,
          this.temas_selecionados.join(",")
        )
          .then((res) => {
            this.setHeadersDialogDetalhes();

            this.dialogDetalhesData = res;
            this.dialogDetalhesSelected = {
              servico: servico,
              nomeServico: this.nomesServicos[servico] || `Serviço ${servico}`,
            };
            this.dialogDetalhes = true;
          })
          .catch((err) => {
            this.$toast.error(
              "Erro ao recuperar dados detalhados das validações.",
              "",
              {
                position: "topRight",
                timeout: 5000,
              }
            );
            throw err;
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      setHeadersDialogDetalhes() {
        this.dialogDetalhesHeader = [];

        this.indicadoresLiberados.forEach((indicador) => {
          this.dialogDetalhesHeader.push({
            text: indicador.toUpperCase(),
            value: indicador,
            align: "end",
          });
        });

        this.dialogDetalhesHeader.sort();

        this.dialogDetalhesHeader.unshift({
          text: "Competência",
          value: "competencia",
          align: "end",
        });
        this.dialogDetalhesHeader.unshift({
          text: "Município",
          value: "nome_municipio",
          align: "end",
        });
        this.dialogDetalhesHeader.unshift({
          text: "Cód. IBGE",
          value: "serv_002",
          align: "end",
        });

        // SERV_006 ao SERV_009
        if (this.temas_selecionados.includes("Realizados")) {
          this.dialogDetalhesHeader.push({
            text: "Aprovado - Realizados",
            value: "aprovacao_realizados",
            sortable: false,
            align: "end",
          });
        }

        // SERV_010 ao SERV_014 (Solicitados, Suspensos e Pendentes)
        if (
          ["Abertos", "Pendentes", "Suspensos"].every((tema) =>
            this.temas_selecionados.includes(tema)
          )
        ) {
          this.dialogDetalhesHeader.push({
            text: "Aprovado - SSP",
            value: "aprovacao_ssp",
            sortable: false,
            align: "end",
          });
        }

        // SERV_015 (Compensações)
        if (this.temas_selecionados.includes("Compensados")) {
          this.dialogDetalhesHeader.push({
            text: "Aprovado - Compensações",
            value: "aprovacao_compensacoes",
            sortable: false,
            align: "end",
          });
        }
      },
      triggerTodosAprovadoServRealizados() {
        this.filteredItemsDialogDetalhesIndicador
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogDetalhesData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogDetalhesData[
                index
              ].aprovacao_realizados = this.flagTodosAprovadoServRealizados;
            }
          });
      },
      triggerTodosAprovadoServSsp() {
        this.filteredItemsDialogDetalhesIndicador
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogDetalhesData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogDetalhesData[
                index
              ].aprovacao_ssp = this.flagTodosAprovadoServSsp;
            }
          });
      },
      triggerTodosAprovadoServCompensacoes() {
        this.filteredItemsDialogDetalhesIndicador
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogDetalhesData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogDetalhesData[
                index
              ].aprovacao_compensacoes = this.flagTodosAprovadoServCompensacoes;
            }
          });
      },
      getCheckboxAprovacaoClass(aprovacao) {
        if (aprovacao === true) return "success--text";
        if (aprovacao === false) return "error--text";
        return "info--text";
      },
      getCheckboxAprovacaoText(aprovacao) {
        if (aprovacao === true) return "Aprovado";
        if (aprovacao === false) return "Reprovado";
        return "Em análise";
      },
      salvaDadosCiente() {
        this.savingCienteValidacao = true;

        IndicadoresServicosV2Service.salvarCienteValidacao({
          serv_005: this.dialogDetalhesSelected.servico,
          temas_selecionados: this.temas_selecionados,
          dados: this.dialogDetalhesData,
        })
          .then((response) => {
            this.$toast.success(`Aprovações salvas com sucesso.`, "", {
              position: "topRight",
              timeout: 3000,
            });

            this.dialogDetalhes = false;
            this.$emit("updateDataTable");
          })
          .catch((error) => {
            this.$toast.error("Erro salvar aprovações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            console.error(error);
          })
          .finally(() => (this.savingCienteValidacao = false));
      },
      downloadArquivoLogsAprovacoes() {
        this.downloadingArquivoLogsAprovacoes = true;

        this.$toast.info("Preparando seu arquivo para download.", "", {
          position: "topRight",
          timeout: 3000,
        });

        IndicadoresServicosV2Service.downloadArquivoLogsAprovacoes(
          this.competencia_de,
          this.competencia_ate,
          this.dialogDetalhesSelected.servico,
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Logs_Aprovacoes_Validacoes_Servicos.xlsx"
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => (this.downloadingArquivoLogsAprovacoes = false));
      },
      userIsAuthorizedByField() {
        return (
          this.userInfo.admin ||
          (this.userInfo.validador &&
            this.authorizedServicos.includes(
              this.dialogDetalhesSelected.servico
            ) &&
            this.authorizedCompanies.includes(this.$store.getters.getCompanyId))
        );
      },
    },
    watch: {
      filteredItemsDialogDetalhesIndicador() {
        this.flagTodosAprovadoServRealizados = this.filteredItemsDialogDetalhesIndicador.every(
          (data) => data.aprovacao_realizados === true
        );

        this.flagTodosAprovadoServSsp = this.filteredItemsDialogDetalhesIndicador.every(
          (data) => data.aprovacao_ssp === true
        );

        this.flagTodosAprovadoServCompensacoes = this.filteredItemsDialogDetalhesIndicador.every(
          (data) => data.aprovacao_compensacoes === true
        );
      },
      dialogDetalhes() {
        if (this.dialogDetalhes === false)
          this.mostrarApenasLinhasComErroCritico = false;
      },
    },
  };
</script>

<style>
  .style-table-dialog-detalhes table thead tr th {
    font-weight: 400;
    font-size: 14px;
    height: 33px;
  }

  .style-table-dialog-detalhes table tbody tr td {
    word-wrap: break-word;
    max-width: 240px;
    font-size: 12.5px;
    padding: 0px 8px !important;
    height: 35px;
    text-align: end !important;
  }
</style>
